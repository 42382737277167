
import * as React from "react"
import {LOG} from "../../tools";
import {useMemo, useState} from "react";
import {TweetToken} from "./TweetToken";
import {TwitterTweetEmbed} from "react-twitter-embed";
import {SColorBG} from "../../../gatsby-plugin-theme-ui-old";
import {AnimatePresence, motion} from "framer-motion";
import {Box, Flex} from "@chakra-ui/react";

const MotionBox = motion(Box)
const options = { year: 'numeric', month: 'long', day: 'numeric', hour:'2-digit',minute:'2-digit' };
export const TweetCard = ({tweetFrontData,listHighlight})=> {
    const {id,aun,mu,nbr} = tweetFrontData
    LOG("DATE",tweetFrontData.date)
    const dateTweet = new Date(tweetFrontData.date.replace(/-/g, "/"))
    LOG("dateTweet",dateTweet)
    const dateTweetStr = useMemo(()=>{
        return dateTweet.toLocaleDateString("fr-FR", options)
    },[dateTweet])
    const listToken = tweetFrontData.lt
    const authorUserName = aun?aun:"unknown"
   const [showEmbed,setShowEmbed] =useState(false)
    const [isHover,setIsHover] = useState(false)

    const [tweetIsMissing,setTweetIsMissing] = useState(false)
    return <MotionBox sx={{maxWidth:'100%',m:'8px',boxShadow: 'rgba(0, 0, 0, 0.1) 0px 1px 3px 0px, rgba(0, 0, 0, 0.06) 0px 1px 2px 0px',
        bg:'white',borderRadius:'3px',p:'4px' }}
                       onMouseEnter={()=>setIsHover(true)} onMouseLeave={()=>setIsHover(false)}
                       animate={isHover?"hover":"default"}
                       variants={{hover:{backgroundColor:SColorBG[900]},default:{backgroundColor:SColorBG[700]} }}

    >
        <Box sx={{lineHeight:1}}>
            <Flex sx={{pl:'4px',pr:'4px', justifyContent: 'space-between'}}>
                <div>
                    <Box sx={{fontWeight: 'bold'}}>@{authorUserName}</Box>
                    <Box sx={{color: 'grey', fontSize: 12}}>{dateTweetStr}</Box>
                </div>
                <Box sx={{textAlign: 'center'}}>
                    <Box sx={{fontWeight: 'bold'}}>{nbr}</Box>
                    <Box sx={{color: 'grey', fontSize: 12}}>retweets</Box>
                </Box>
            </Flex>
            {!showEmbed &&
            <MotionBox sx={{lineHeight: 1.2, p: '4px',pl:'8px', fontSize: 15}}>{listToken.map((token, i) => <TweetToken key={i}
                                                                                                          token={token}
                                                                                                          listHighlight={listHighlight}/>)}</MotionBox>
            }
            <AnimatePresence>
                {showEmbed &&
                <MotionBox
                    sx={{minHeight:'150px'}}
                    initial={{ height:0}}
                    animate={{height:'auto'}}
                >
                    <TwitterTweetEmbed
                        tweetId={`${id}`}
                        options={{"theme": "blue", "width": 450, 'align': 'center'}}
                        onLoad={tweet => {
                            if(!tweet){
                                setTweetIsMissing(true);
                            }
                        }}
                    />
                    {tweetIsMissing && <Box sx={{height:'100%',minHeight:'120px',display:'flex',textAlign:'center',color:'grey',
                        flexDirection:'column',justifyContent:'center'}}>
                        <div>Le tweet n'existe plus... </div></Box>}
                </MotionBox>}
            </AnimatePresence>

        </Box>
        <Box sx={{position:'relative',height:'20px'}}>
            <AnimatePresence>
                {(!showEmbed && isHover)  &&
                <MotionBox onClick={()=>setShowEmbed(true)}
                            sx={{position:'absolute',right: '10px',cursor:'pointer',color:'grey'}}
                            initial={{ opacity: 0,y:-12 }}
                            animate={{ opacity: 1,y:-5 }}
                            exit={{ opacity: 0,y:-12 }}
                >Afficher</MotionBox>}
                {(showEmbed && isHover)  &&
                <MotionBox onClick={()=>setShowEmbed(false)}
                            sx={{position:'absolute',right: '10px',cursor:'pointer',color:'grey'}}
                            initial={{ opacity: 0,y:-10 }}
                            animate={{ opacity: 1,y:-5 }}
                            exit={{ opacity: 0,y:-10 }}
                >Réduire</MotionBox>}
            </AnimatePresence>

        </Box>
    </MotionBox>
}