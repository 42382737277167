
import * as React from "react"
import {LOG} from "../../tools";
import {AreaInfo} from "./area/AreaInfo";
import {AreaTweets} from "./area/AreaTweets";
import {AreaGraph} from "./area/AreaGraph";
import {useMemo, useState} from "react";
import {Box, Flex, Heading} from "@chakra-ui/react";
import {AnimatePresence, motion} from "framer-motion"
import {SColorBG} from "../../../gatsby-plugin-theme-ui-old";
import {CloseIcon,UpDownIcon} from '@chakra-ui/icons'
import {sendEvent} from "../../tools/analytics";
const MotionBox = motion(Box)
const MotionHeading = motion(Heading)

export const TweetTopic = ({tweetTopicData})=> {
    const {id,n,nb} = tweetTopicData
    const listToken = tweetTopicData.lt
    const trendTopic = tweetTopicData.t
    const listTweetFront = tweetTopicData.ltf
    const dateStartTopic = tweetTopicData.ds
    const durationTopic = tweetTopicData.dur
    const timeWeightsTopic = tweetTopicData.tw
    const listPropTags = tweetTopicData.hasOwnProperty("pt")?tweetTopicData["pt"]: []

    const [isHover,setIsHover] = useState(false)
    const [isActive,setIsActive] = useState(false)

    const nameAnimate = useMemo(()=> {
            if (isActive){
                return isHover?"hover":"default"
            }else{
                return isHover?"noact_hover":"noact_default"
            }
        }
        ,[isHover,isActive])




    return <MotionBox sx={{p:'4px',mt:isActive?'8px':0,pb:'4px',mb:isActive?3:1,position:'relative',
    }}
                      onMouseEnter={()=>setIsHover(true)} onMouseLeave={()=>setIsHover(false)}
                      animate={nameAnimate}
                      variants={{
                          hover:{backgroundColor:SColorBG[500]},
                          noact_hover:{backgroundColor:SColorBG[700]},
                          noact_default:{backgroundColor:SColorBG[500]},
                          default:{backgroundColor:SColorBG[500]}
                      }}
    >
        <MotionHeading as={"h2"} sx={{m:0,p:0,fontFamily:'body',position:'absolute',textTransform:'uppercase',fontSize:18}}

                       variants={{hover:{y:-7,x:0},default:{y:0,x:0},noact_hover:{x:5},
                           noact_default:{x:0}, }}
        ><Box as={"span"} sx={{bg:'text.100',color:'bg.500',p:'4px'}}>{n}</Box></MotionHeading>

        <AreaGraph listTweetFront={listTweetFront} timeWeightsTopic={timeWeightsTopic}
                   dateStartTopic={dateStartTopic}
                   durationTopic={durationTopic}/>

        {isActive && <MotionBox
            sx={{width: '100%', display: 'flex', justifyContent: 'flex-start',flexWrap:'wrap', alignItems: 'stretch',pb:'16px'}}>
            <AreaInfo name={n} nb={nb} trend={trendTopic} listToken={listToken} isActive={isActive} listPropTags={listPropTags}/>
            <AreaTweets listTweetFront={listTweetFront} listToken={listToken} isActive={isActive}/>
        </MotionBox>}
        <AnimatePresence>
            {nameAnimate =="noact_hover" &&
            <MotionBox onClick={()=> {
                setIsActive(true)
                sendEvent("clickTopic",{})
            }}
                       sx={{position:'absolute',top:0,right: '10px',cursor:'pointer',color:'text.100'}}
                       initial={{ opacity: 0,y:5 }}
                       animate={{ opacity: 1,y:0 }}
                       exit={{ opacity: 0,y:5 }}
            ><UpDownIcon/></MotionBox>}
        </AnimatePresence>
        {!isActive &&
        <Box onClick={()=> {
            setIsActive(true)
            sendEvent("clickTopic",{})
        }}
             sx={{position:'absolute',top:0,width:' 100%',height:'100%',cursor:'pointer',color:'text.100',textTransform:'uppercase'}}
        ></Box>}

        <AnimatePresence>
            {((nameAnimate =="default")| (nameAnimate=="hover")) &&
            <MotionBox onClick={()=>setIsActive(false)}
                       sx={{position:'absolute',top:0,right: '10px',cursor:'pointer',color:'text.100'}}
                       initial={{ opacity: 0,y:-5 }}
                       animate={{ opacity: 1,y:0 }}
                       exit={{ opacity: 0,y:-5 }}
            ><CloseIcon/></MotionBox>}
        </AnimatePresence>

    </MotionBox>
}