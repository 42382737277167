import React, {useContext, useEffect, useMemo, useState} from "react"
import PersonHeader from "./components/PersonHeader";
import {LOG, S3_PUBLIC} from "../tools";
import {Box, Container, Flex, Spinner} from "@chakra-ui/react";
import {getDataPageLoad, getDataPageTag} from "../api/getData";
import PersonCard from "./PersonCard";
import TagContentHot from "../tag/components/TagContentHot";
import {GraphTimeLineMain} from "../graph/GraphTimeLineMain";
import {HeaderSection} from "../mainPage/HeaderSection";
import DataLoadContext from "../../context/DataLoadContext";
import TagContentVocabViz from "../tag/components/TagContentVocabViz";
import {TweetTopic} from "../tweet/topic/TweetTopic";
import TagTimeline from "../tag/TagTimeline";
const PersonPage = ({personJson})=>{
    const name = personJson.n
    const id = personJson.id
    const [dataHeader,setDataHeader] = useState({"lt":[],"nb":0})
        const [dataMainGraph,setDataMainGraph] = useState({"d": [],
    "sd": "2021-05-24 00:00:00"})
         const [dataTagVocabs,setDataTagVocabs] = useState(null)
        const [listTweetTopic,setListTweetTopic] = useState(null)

    const {lastUpdate,updateLastUpdate} = useContext(DataLoadContext);
    useEffect(() => {
    getDataPageLoad("pageTag").then((d) =>{
        updateLastUpdate(d)
    });
  }, []);

    const nameFAnalyzer = "PageTags"
    const lastUpdatePageTag = lastUpdate.hasOwnProperty(nameFAnalyzer)?lastUpdate[nameFAnalyzer]:null
    useEffect(() => {
        LOG("lastUpdatePageTag",lastUpdatePageTag)
        if(lastUpdatePageTag!=null){
            getDataPageTag(id,lastUpdatePageTag).then((d) =>{
                LOG("getDataPageTag",d)
                if(d !=null){
                    setDataHeader(d["hc"])
                    setDataMainGraph(d["mg"])
                    setDataTagVocabs(d["v"])
                    setListTweetTopic(d["ltt"])

                }
            });
        }

  }, [lastUpdatePageTag]);

     const listTweetTopicSorted = useMemo(()=>{
        if(listTweetTopic!=null){
            return listTweetTopic.sort(function(a, b) {
    return a.vs - b.vs;
})
        } else {return null}

    }, [listTweetTopic])

        const listTweetTopicIsLoading = listTweetTopic ==null
    return <Container maxW="container.xl" sx={{margin: `10px auto`,
                pl:['2px','10px','10px'],
                pr:['2px','10px','10px'],
            }}>

        <PersonHeader id={id} name={name} dataHeader={dataHeader}/>
        <TagContentVocabViz name={name} dataVocabs={dataTagVocabs}/>
        <TagTimeline tagId={id}/>
         <TagContentHot key={id}  personId={id}
          />

        <Box height={'15px'} />
        <HeaderSection  text={"Activité des dernières semaines"} flexGrowRight={18} />
        <GraphTimeLineMain dataMainGraph = {dataMainGraph} stepWindow={60*24} stepWindowWord={60*24} />

        {(!listTweetTopicIsLoading) &&
                            <Box sx={{p:1,pt:0}}>
                            {listTweetTopicSorted.map((tweetTopicData,i)=><TweetTopic key={tweetTopicData.id} tweetTopicData={tweetTopicData}/>)}
                            </Box>
        }
        {listTweetTopicIsLoading && <Flex justifyContent={"center"} height={500} alignItems={"center"}> <Spinner size={"xl"} color="text.100"/> </Flex>}

         </Container>
}

export default PersonPage