
import {Avatar, Box, Flex, Heading, Spinner, useMediaQuery} from "@chakra-ui/react"
import { motion } from "framer-motion"
import React, {useContext, useEffect, useMemo, useState} from "react";
import {SColorBG} from "../../gatsby-plugin-theme-ui-old";
import { Link } from "gatsby"
import {LOG} from "../tools";
import {getFullUrlFromTagTypeAndUrl, getTypeFromTagId, getUrlImgFromTagId} from "../tag/tools";
import {getDataHotTag} from "../api/getData";
import DataLoadContext from "../../context/DataLoadContext";

const MotionBox = motion(Box)
const MotionHeading = motion(Heading)

const PersonCard = ({name,personId,url})=>{
    // images : https://d-qn.shinyapps.io/rtify/
    const [isLowerThan580] = useMediaQuery("(max-width: 580px)")
    const urlImg =  getUrlImgFromTagId(personId,100)
    const [isHover,setIsHover] = useState(false)
      const [dataEvol,setDataEvol] = useState(null)
     const [dataNb,setDataNb] = useState(null)
     const [dataVocab,setDataVocab] = useState(null)


    const tagType = getTypeFromTagId(personId)
    const fullUrl = getFullUrlFromTagTypeAndUrl(tagType,url)
    const {lastUpdate} = useContext(DataLoadContext);
    const lastUpdateHotTags = lastUpdate.hasOwnProperty("HotTags")?
                                                lastUpdate["HotTags"]:null
   useEffect(() => {
        if(lastUpdateHotTags!=null){
            getDataHotTag(personId,lastUpdateHotTags).then((d) =>{
        if (d != null){
            setDataEvol({
            t:d["t"],
        })
        setDataNb({
            nb:d["nb24"]
        })
        setDataVocab({
            lt:d["lt"]
        })
        }

    });
        }
  }, [lastUpdateHotTags]);

    return <Box sx={{width:["100%","50%","33%"], mt:isLowerThan580?'20px':'15px'}}>
        <MotionBox sx={{bg:'bg700',m:'8px',borderRadius:'5px',boxShadow:'rgba(0, 0, 0, 0.18) 0px 2px 4px'}}
        onMouseEnter={()=>setIsHover(true)} onMouseLeave={()=>setIsHover(false)}
                animate={(isLowerThan580 | isHover)?"hover":"default"}
                    variants={{hover:{backgroundColor:SColorBG[900]},default:{backgroundColor:SColorBG[700]} }}
        >
            <MotionBox sx={{display:'flex',justifyContent:'center'}}
                variants={{hover:{y:-30,opacity:1},default:{y:-25,opacity:0.8} }}
            > <Avatar
                sx={{borderStyle:'solid',borderWidth:'6px',borderColor:'bg.900'}}
                size="xl" src={urlImg} width={100} /></MotionBox>
        <MotionHeading
            variants={{hover:{y:-3},default:{y:-0} }}
            sx={{textAlign:"center",mt:isLowerThan580?'-30px':'-20px',mb:0,fontSize:30,fontWeight:'bold',fontFamily:'heading',color:'text.300'}}>{name}</MotionHeading>
            <MotionBox
                variants={{hover:{y:-3},default:{y:-0} }}
            >
            <Box sx={{textAlign:'right',pr:'16px',fontSize:'12px',color:'text.50'}}>24 dernières heures</Box>
            <Flex sx={{p:'2px',minHeight:0,flexWrap:'wrap'}} justifyContent={"center"}>
            <GlobalInfoCard typeInfo={"nb"} data={dataNb}/>
            <GlobalInfoCard typeInfo={"evol"} data={dataEvol}/>
            <GlobalInfoCard typeInfo={"vocab"} data={dataVocab}/>
            </Flex>
            <Flex sx={{justifyContent:'flex-end'}}>
                <MotionBox variants={{hover:{opacity:1,y:-1,transition:{delay:0.1}},default:{opacity:0,y:3} }} sx={{fontSize:18,m:'4px',fontWeight:'bold',fontFamily:'heading'}}>

                    <Link
                        to={ fullUrl }><Box sx={{
                            fontFamily:'body',
                            fontWeight: 'bold',
                            color:'text.100',
                            "borderRadius": "sketchy1",
                            "textDecoration": "none",
                            p: '8px',
                            //"textTransform": "uppercase",
                            "letterSpacing": "body",
                            "&:hover": {
                              "bg": "bg.600",
                              'color': 'text',
                            }
                          }} as={"span"}>-> Plus de détail</Box></Link></MotionBox>
            </Flex>
            </MotionBox>
          </MotionBox>
    </Box>
}



const GlobalInfoCard = ({typeInfo, data})=>{
    const contentDiv = useMemo (() => {
        if (data == null){
            return <Flex justifyContent={"center"}><Spinner color="text.100" /></Flex>
        }
        switch (typeInfo) {
            case "nb":
                return <>
                    <Flex sx={{
                        textAlign: 'center',
                        fontSize: 25,
                        fontWeight: 'bold',
                        letterSpacing: 1.8,minHeight: '50px',
                        justifyContent: 'center',
                        flexDirection: 'column'
                    }}>
                        <div>{data["nb"].toLocaleString()}</div>
                    </Flex>
                    <Box sx={{textAlign: 'center',textTransform:'uppercase',color:'text100',
                        lineHeight:1.2, fontSize: 16, fontWeight: 'body', letterSpacing: 1.5}}>Tweets</Box>
                       </>
            case "evol":
                const evol = data["t"] - 100
                return <>
                    <Flex sx={{
                        textAlign: 'center',
                        fontSize: 25,
                        fontWeight: 'bold',
                        letterSpacing: 1.8,
                        minHeight: '50px',
                        justifyContent: 'center',
                        flexDirection: 'column'
                    }}>
                        <div sx={{}}>{evol>0?"+":""}{evol}%</div>
                    </Flex>
                    <Box sx={{textAlign: 'center',textTransform:'uppercase',color:'text100',
                        lineHeight:1.2, fontSize: 16, fontWeight: 'body', letterSpacing: 1.5}}>Tendance</Box>
                </>
            case "vocab":
                const listToken = data["lt"].map(token => token.TC)
                return <>
                    <Flex sx={{
                        textAlign: 'center',
                        fontSize: 25,
                        fontWeight: 'bold',
                        letterSpacing: 1.8,
                        minHeight: ['50px','200px','200px'],
                        justifyContent: 'center',
                        flexDirection: 'column',
                    }}>
                        <Flex sx={{justifyContent:"center",fontWeight:'bold',flexWrap:'wrap',p:'4px'}}>{listToken.map((token,i)=>
                            <Box key={i} sx={{bg:'secondary.100',ml:'4px',mr:'4px',mt:'4px',p:'2px', borderRadius:'4px',
                                borderColor:'secondary.300',borderStyle:'solid',borderWidth:'1px',
                                fontSize:'13px',color:'text.300',whiteSpace:'normal',fontFamily:'heading',fontWeight:'bold'}}>
                    {token} </Box>)} </Flex>
                    </Flex>
                    <Box sx={{textAlign: 'center',textTransform:'uppercase',lineHeight:1.2,color:'text.100',
                        fontSize: 16, fontWeight: 'body', letterSpacing: 1.5, pb:'8px'}}>Vocabulaire</Box>
                    </>
            default:
                return <div></div>
        }
    },[typeInfo,data])

    return <Box sx={{width:typeInfo=="vocab"?['100%','100%','100%']:['50%','50%','50%'], minHeight:'100px',mt:'6px'
    }}>
                   <Flex sx={{bg:'bg.300',ml:'4px',mr:'4px',mt:0,borderRadius:'5px',minHeight:'80px', height:'100%',
                      boxShadow: 'rgba(0, 0, 0, 0.1) 0px 1px 3px 0px, rgba(0, 0, 0, 0.06) 0px 1px 2px 0px',
                justifyContent:'center',flexDirection:'column'}}>
                       {contentDiv}
        </Flex>
            </Box>
}

export default PersonCard
