
import React, {useMemo} from "react";
import {AreaDetails} from "./AreaDetails";

import { motion } from "framer-motion"
import {SColorGreen, SColorRed} from "../../../../gatsby-plugin-theme-ui-old";
import {Box, Flex} from "@chakra-ui/react";

const MotionBox = motion(Box)

export const AreaInfo = ({name,nb,trend, listToken,listPropTags})=>{


    return <MotionBox sx={{width:['100%','50%','50%'],position:'relative'}}>

        <Flex sx={{height:'100%',mt:0,width:'100%',flexWrap:'wrap'}}>
            <Box sx={{width:['100%','100%','25%']}}>
                <Box sx={{textAlign:'right',pr:3,fontSize:'10',color:'text50'}}>24 dernières heures</Box>
                <CardItemNb nb={nb}/>

                <CardItemActivity trend={trend}/>
            </Box>
            <AreaDetails listToken={listToken} listPropTags={listPropTags}/>
        </Flex>

    </MotionBox>
}

const CardItemNb = ({nb})=>{
    const nb_str  = nb
    return <Box sx={{width:['100%']}}>
        <Flex sx={{bg:'bg',m:2,borderRadius:'5px',minHeight:'80px', height:'100%',
            boxShadow: 'rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px',
            display:'flex',justifyContent:'center',flexDirection:'column'}}>
            <Box sx={{textAlign:'center',fontSize:25,fontWeight:'bold',letterSpacing:1.8,minHeight:50,display:'flex',
                justifyContent:'center',flexDirection:'column'}}><div>{nb_str}</div></Box>
            <Box sx={{textAlign:'center',fontSize:15,fontWeight:'body',letterSpacing:1.5}}>Nombre de tweets</Box>
        </Flex>
    </Box>
}

const CardItemActivity = ({trend})=>{
    const diffValueShowed = useMemo(()=>{
        
        if(trend>=999){
            return <Box sx={{color:'green.500'}}> > +900%</Box>
        }
        else if (trend >100){
            return <Box sx={{color:'green.500'}}> +{trend -100 }%</Box>
        }else if(trend <100){
            return <Box sx={{color:'red.500'}}> -{(trend -100)*-1 }%</Box>
        }
        else{
            return <div> +{trend -100 }%</div>
        }
    }, [trend])
    return <Box sx={{width:['100%']}}>
        <Flex sx={{bg:'bg',m:2,mt:3,borderRadius:'5px',minHeight:'80px', height:'100%',
            boxShadow: 'rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px',
            //boxShadow: 'rgba(0, 0, 0, 0.1) 0px 1px 3px 0px, rgba(0, 0, 0, 0.06) 0px 1px 2px 0px',
            justifyContent:'center',flexDirection:'column'}}>
            <Box sx={{textAlign:'center',fontSize:25,fontWeight:'bold',letterSpacing:1.8,minHeight:'50px',display:'flex',
                justifyContent:'center',flexDirection:'column'}}>{diffValueShowed}</Box>
            <Box sx={{textAlign:'center',fontSize:15,fontWeight:'body',letterSpacing:1.5}}>Tendance</Box>
        </Flex>
    </Box>
}