import React from "react"
import PersonPage from "../components/persons/PersonPage";
import {LOG} from "../components/tools";
 import { graphql } from "gatsby"
import Layout from "../components/layout";

export default function PersonTemplate({
                                         //data: {person: post },
                                         pageContext: { content } }) {
     const {n} = content

    return  <Layout title={`${n}`}><PersonPage personJson={content}
    /></Layout>
}