
import React from "react";
import {SColorBG} from "../../../../gatsby-plugin-theme-ui-old";
import {TagCard} from "../../../tag/TagCard";
import {Box, Flex} from "@chakra-ui/react";

export const AreaDetails = ({listToken,listPropTags})=>{

    const listPropTagsFiltered = listPropTags.filter((propTag) => propTag[1]>10)

    return <Box sx={{width:['100%','100%','75%'],bg:`rgba(${SColorBG['rgb'][700]},0.7)`,p:'4px',pt:'16px'}}>
        <CardItemVocab listToken={listToken.slice(0,10)}/>
        <div>
            {listPropTagsFiltered.map((propTag) => <TagCard
                key={propTag[0]}
            tagId={propTag[0]}
            tagProp={propTag[1]}
            diffNormal={propTag[2]}
            /> )}

        </div>

    </Box>
}
const CardItemVocab = ({listToken})=>{
    return <Box sx={{width:['100%']}}>

        <Flex sx={{bg:'bg.500',ml:'8px',mr:'8px',mt:0,borderRadius:'5px',minHeight:'80px',p:'4px',
                boxShadow: 'rgba(0, 0, 0, 0.1) 0px 1px 3px 0px, rgba(0, 0, 0, 0.06) 0px 1px 2px 0px',justifyContent:'center',flexDirection:'column'}}>
            <Flex sx={{textAlign:'center',fontSize:25,fontWeight:'bold',letterSpacing:1.8,lineHeight:1,minHeight:'50px',justifyContent:'center',flexDirection:'column'}}>
                <Box>{listToken.map((token,i)=><Box as={"span"} key={i}
                                     sx={{bg:'bg.900',m:'4px',p:'1px',fontSize:14,color:'text.500',whiteSpace:'normal',fontWeight:'bold'}}>
                    {token.hasOwnProperty("T")?token.T:token.TC} </Box>)} </Box>
            </Flex>
            <Box sx={{textAlign:'center',fontSize:15,fontWeight:'body',letterSpacing:1.5}}>Vocabulaire</Box>
        </Flex>
    </Box>
}