



const maxAreaHeight = 500
const minAreaHeight = 100

export const sxTopicArea = {
    maxHeight:maxAreaHeight,
    minHeight:minAreaHeight,
}