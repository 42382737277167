
import React, {useMemo} from "react";
import {TweetCard} from "../../card/TweetCard";
import {sxTopicArea} from "./area_tools";
import {SColorBG, SColorText} from "../../../../gatsby-plugin-theme-ui-old";
import {LOG} from "../../../tools";
import {Box} from "@chakra-ui/react";

export const AreaTweets = ({listTweetFront,listToken})=>{

    const listHighlight = useMemo(()=>{
        let arrayTmp = []
        listToken.map((token)=>{
               const array_token_TC = token.TC.split(" ")
            array_token_TC.map((tokenTC)=>{
                arrayTmp.push({TC:tokenTC, T:tokenTC, W:token.W})
            })
        })
        return arrayTmp

    },[listToken])

    return <Box sx={{width:['100%','50%','50%'],bg:'bg.500',position:'relative'}}>
        <Box sx={{position:'absolute',ml:'2px',mr:'14px',width:'calc(100% - 16px)',borderBottom:`solid 1px ${SColorBG[700]}`,height:'30px',textAlign:'right',fontSize:'16px',color:'text.50',pr:'16px',bg:`rgba(${SColorBG['rgb'][500]},1)`}}>les tweets</Box>
        <Box sx={{...sxTopicArea,mt:0,overflow:'auto',borderRadius:'3px',p :'4px',pt:'32px', border:`solid 1px ${SColorBG[900]}` }}>
            {listTweetFront.map((tweetFrontData,i) =><TweetCard key={tweetFrontData.id} tweetFrontData={tweetFrontData}
        listHighlight={listHighlight}
        /> )}</Box>
        </Box>
}